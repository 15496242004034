import React from 'react';
// import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import './index.css'
import { Link } from 'react-router-dom';

export default function MainDescription() {
    // const matches = useMediaQuery('(min-width: 768px)');

    return (
        <div className='home-details no-web-display'>
            <h1 className='heading site-title'>
                Streameast <span className='heading'> - Home to high quality live sports streams and upcoming fixtures.</span>
            </h1>

            <h2 className='collection'>Get to know Streameast and the service it provides.</h2>
            <p className='card-description'>Streameast is a professional high quality, multi player live streaming sports platform, which aims to provide streams of most popular sports in the world.</p>
            <p className='card-description'>
                StreamEast live streams Soccer, Football, NFL, MLB, F1 , NBA , Boxing, tennis , UFC and many more. We know other platforms Hesgoal , footbite, totalsportek , Methstreams also provide the same services but based on our user experiences we assure you that we are always way ahead to provide the best free HD quality live streams.
            </p>

            <h2 className='collection'>Is Streameast Soccer Secure?</h2>
            <p className='card-description'>
                YESSSSSSS….. StreamEast is a highly secure website. You might have some reservations based on your past experience with similar platforms, but we assure you that we do not ask for or store any personal data from our valuable users.
            </p>

            <h2 className='collection'>Do I need an account / signup for Streameast Soccer?</h2>
            <p className='card-description'>
                You might be having accounts for other streaming services, but for stream east you do not need to sign up. You can watch all games without creating an account.
            </p>

            <h2 className='collection'>Can I watch Sports in HD quality in Stream east?</h2>
            <p className='card-description'>
                Yesssss, we take our users seriously and we do not compromise on the quality of our streams. Our streams are in HD quality, our website has multiple players to ensure that our users are having access to best high quality live streams.
            </p>

            <h2 className='collection'>Can I watch Sports in HD quality in Stream east?</h2>
            <p className='card-description'>
                Yesssss, we take our users seriously and we do not compromise on the quality of our streams. Our streams are in HD quality, our website has multiple players to ensure that our users are having access to best high quality live streams.
            </p>

            <h2 className='collection'>Which platform should I prefer to watch best live stream of matches, Hesgoal , footbite, totalsportek , Methstreams or StreamEast?</h2>
            <p className='card-description'>
                We understand that there are multiple choices to go for any platforms to watch your favorite games but among all these we are confident that only we at Stream east provide you the best experience. We provide multiple high quality links of many single matches to make your experience fun, easy and hustle less.
            </p>

            <h2 className='collection'>Do you need to pay for StreamEast?</h2>
            <p className='card-description'>
                No, Streameast Soccer is absolutely free. We do not charge for any matches. 
            </p>

            <h2 className='collection'>How is stream east better than other streaming platforms?</h2>
            <p className='card-description'>
                We understand there are many platforms they provide the same services as we do, Hesgoal, footbite, totalsportek , Methstreams are also such platforms . But because of how we use multiple players to enhance the viewing experience of our users, we are more than confident that stream east is the best choice and only right choice to watch free live streams of HD quality sports.
            </p>

            <div>
                <ul className='heading-list'>
                    <li>
                        <h2 className='collection'>Most popular sports streams covered by Streameast Soccer are as follow:</h2>
                    </li>
                    <li>
                        <p className='nav-link'>
                            <span className='collection'> <Link to={'/streams/Soccer'}>Soccer Streams</Link></span>
                        </p>
                        <p className='card-description'>
                            If you have been looking for a live streaming solution to watch the best high quality live streaming soccer matches then your search is finally over. Streameast Socer is the best platform of live sports broadcasts. We offer the best free live streaming of soccer matches. Check out the schedule of live soccer matches and their times of free HD quality live streaming. Stream east provides the best reliable free live streaming of matches. 
                        </p>
                        <p className='card-description'>
                            All the major soccer events like La Liga, Premier League, Serie A, Ligue 1 and Bundesliga are all free live streamed on our website. Our platform is always the option for the soccer lovers because we ensure that you are having safe live streaming experience in the HD quality.
                        </p>
                        <p className='card-description'>
                            Not everyone is privileged enough to go see live matches therefore we are bringing the best viewing experience to your homes. We know that there are thousands of soccer fans who look for reddit soccer streams to watch live streams of soccer without any hustle.
                        </p>
                        <p className='card-description'>
                            We offer the best reddit soccer streams to enhance your experience. Streameast soccer provides the best soccer screams reddit. our links of matches are safe and hustle free .our football live HD quality streams provides the best live streaming experience to our users.
                        </p>
                        <p className='card-description'>
                        If you have been looking for other platforms such as streameast app and streameast live stream soccer then we will be happy to tell you that we offer better services then the streameast live stream soccer games.
                        </p>
                    </li>
                    <li >
                        <p className='nav-link'> 
                            <span className='collection'><Link to={'/streams/NFL'}>Live NFL Matches</Link></span>
                        </p>
                        <p className='card-description'>
                            If you are looking for NFL free streams to watch high quality live streaming of NFL matches for free then congratulations, you have found that best platform as our website streameast provides the best free live streaming of NFL matches.Our platform has everything covered from NFL team and NFL matches, NFL Playoff and super bowl.  
                        </p>
                        <p className='card-description'>
                            We ensure that our links are hustle free and works perfectly well to enhance your experience of watching free live streaming of NFL games, unlike streameast where you have to go through a hustle to stream your favorites matches we offer a better alternative to streameast website and streameast app .our NFL streams are always free HD quality of live streaming. 
                        </p>
                        <p className='card-description'>
                            Our NFL streams are always free HD quality of live streaming .we provide multiple links of NFL games for your convenience, now you do not need to look for streameast. 
                        </p>
                    </li>
                    <li >
                        <p className='nav-link'>
                            <span className='collection'><Link to={'/streams/NBA'}>NBA Live</Link></span>
                        </p>
                        <p className='card-description'>
                            We provide the best free live streaming of NBA games .All basketball lovers who are looking for best quality links to enjoy NBA Matches look no further. our platform stream east provides best free link of NBA matches . We are known to provide the best quality NBA live stream links .we provide the best links to watch free HD quality NBA games.                        
                        </p>
                    </li>
                    <li >
                        <p className='nav-link'>
                            <span className='collection'><Link to={'https://www.sportsfeed24.to/streams/nhl'}>NHL Streams</Link></span>
                        </p>
                        <p className='card-description'>
                            For all hockey fans who are looking for best NHL HD quality free live stream of games, and who does not want to look for streameast app and streameast website for the free livestreaming of hockey games, look no further we got you cover. Our platform streameast soccer provides the best live streaming of NHL games. 
                        </p>
                        <p className='card-description'>
                            We provide multiple links to HD quality free live stream of NHL games so that you could experience the best NHL games without any interruption and hustle free. Users on our platform can stream the best quality free live stream of Stantley Cup playoffs and live stream of regular NHL games.
                        </p>
                    </li>
                    <li >
                        <p className='nav-link'>
                            <span className='collection'>
                                <Link to={'/streams/F1'}>Formula F1</Link>/
                                <Link to={'/streams/Motogp'}>MotoGP</Link>
                            </span>
                        </p>
                        <p className='card-description'>
                            All lovers of Formula 1, are you tired of looking for a free best quality streams of Formula 1 races then we got you. Streameast Soccer proud to enhance your experience of thrills of the F1 races. Our F1 streams are free of cost and our F1 live streams are in HD quality. Now you all F1 fans have access to all your favorite F1 races of season for free. 
                        </p>
                        <p className='card-description'>
                            Users on our platform can watch live free stream F1, live free stream Indycar, live free stream NASCAR, live free stream MotoGP. We know that many of our users have been using various apps and websites for F1 races. Knowing this, we have developed our website to provide the best F1 free live streams experience, surpassing what other platforms can offer.
                        </p>
                    </li>
                    <li >
                        <p className='nav-link'>
                            <span className='collection'><Link to={'https://www.sportsfeed24.to/streams/mlb'}>MLB</Link></span>
                        </p>
                        <p className='card-description'>
                            If you are looking for free live streams of best boxing matches then you are on the right platform. Streameast Soccer offering the best free links of boxing matches in HD quality. You will be provided the best experience of free live streaming of boxing matches in best quality. we are better than other websites because we care for your experience of viewing hence we provide the links that gives you free live streams of boxing events without any charges .
                        </p>
                    </li>
                    <li>
                        <p className='nav-link'>
                            <span className='collection'><Link to={'/streams/Boxing'}>Boxing Streams</Link></span>
                        </p>
                        <p className='card-description'>
                            If you are looking for free live streams of best boxing matches then you are on the right platform. Streameast Soccer offering the best free links of boxing matches in HD quality. You will be provided the best experience of free live streaming of boxing matches in best quality                        
                        </p>
                        <p className='card-description'>
                            we are better than other websites because we care for your experience of viewing hence we provide the links that gives you free live streams of boxing events without any charges.
                        </p>
                    </li>
                    <li>
                        <p className='nav-link'>
                            <span className='collection'><Link to={'/streams/UFC'}>UFC Live Streams</Link></span>
                        </p>
                        <p className='card-description'>
                            How does the streameast soccer website provide the best HD quality free live streams of UFC events on streameast. For the best experience of UFC live streams our platform is the right choice.                         
                        </p>
                        <p className='card-description'>
                            We provide the best free live stream UFC matches in HD quality. Our links of UFC matches ensures that you have a hustle free and easy live streaming experience.  Users can watch live free steam of UFC286 and live free stream of pay-per-view and live free streams of UFC fight night events.
                        </p>
                    </li>
                    <li>
                        <p className='nav-link'>
                            <span className='collection'><Link to={'/streams/UFC'}>Live Tennis</Link></span>
                        </p>
                        <p className='card-description'>
                            To watch the best quality of free ATP and WTP matches in the best quality live streaming our platform stream streameast soccer, Is the right place. The experience of free live streaming of tennis is ensured by our website.  We ensure that you will have the best time while watching free live stream of ATP and free live stream of WTP matches in HD quality. 
                        </p>
                        <p className='card-description'>
                        If you have been tired of using streameast app and streameast website and their quality of links then look no further ,finally you have found the right platform . Unlike streameast app and streameast .to website, we provide the best free live streams HD quality tennis matches.                        
                        </p>
                    </li>
                    <li>
                        <h2 className='collection'>Conclusion</h2>
                        <p className='card-description'>
                            StreamEast Soccer stands out as the ultimate platform for live sports streaming, offering free HD quality streams across a wide range of sports, including soccer, NFL, NBA, MLB, NHL, F1, boxing, tennis, UFC, and more.
                        </p>
                        <p className='card-description'>
                            With no need for account signups and a focus on user experience, StreamEast Soccer ensures hassle-free, secure access to multiple player and links for each game, providing an unparalleled streaming experience. Whether you are a fan of soccer or any other major sport, StreamEast commitment to high-quality streams and convenience makes it the best choice for sports enthusiasts.
                        </p>
                    </li>
                </ul>
            </div>
        </div>        
    );
};