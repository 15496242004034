import React, { useEffect, useState } from 'react';
import { notification} from 'antd';
import { updateStream } from '../../apis/fixture.api';
import { MESSAGES } from '../../utils/constant';
import Loader from '../common/Loader';
import { useNavigate, useParams } from 'react-router-dom';

export default function UpdateStreamComponent() {
    const [loader, setLoader] = useState<boolean>(false);
    const navigate = useNavigate();
    const { teams } = useParams();

    const updateStreamLinks = async () => {
        setLoader(true);
        try {

            let teamA: string | undefined = teams;
            let teamB: string | undefined = '';
            if(teamA && teamA.includes('-vs-')){
                const teamsArr = teamA.split('-vs-');
                teamA = teamsArr[0];
                teamB = teamsArr[1];
            }
            const payload = {
                teamA: teamA,
                teamB: teamB
            }
            await updateStream(payload);
            notification.open({
                message: MESSAGES.UPDATE_STREAM_SUCCESS
            });
        
            navigate(`/fixture/${teams}`)
            setLoader(false); 
        } catch (error) {
            notification.open({
                message: MESSAGES.UPDATE_STREAM_FAILURE
            });
        }
    };

    useEffect(() => {
        updateStreamLinks()
    }, []);

    return (
        <>
            {loader ? <Loader /> : <></>}
        </>
    );
};