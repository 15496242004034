import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import { ConfigProvider, Layout } from 'antd';
import './index.css'

import Menu from '../common/Menu';
import MenuItem from '../common/MenuItem';
import logo from '../../assets/logo/streameast-logo.png';
import DrawerComponent from '../common/Drawer';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import { ConfigConsumerProps } from 'antd/lib/config-provider';
import { MenuOutlined } from '@ant-design/icons';
import { categories, HOME_PATH } from '../../utils/constant';

const { Header } = Layout;

const styles = {
  headerHamburger: (themeConfig: ConfigConsumerProps) => ({
    color: themeConfig?.theme?.token?.colorPrimary,
  }),
  headerDrawer: {
    width: 'auto',
    borderRight: 'none',
  },
};

export default function HeaderComponent() {
  const themeConfiguration = useContext(ConfigProvider.ConfigContext);
  const [headerDrawerOpen, setHeaderDrawerOpen] = useState<boolean>(false);
  const matches = useMediaQuery('(min-width: 1000px)');

  const MenuItems = (
    <>
      {categories?.map((cat: any, index: any) => (
      <MenuItem key={index}>
        <Link className='menu-items' to={'/streams/' + cat.label} >{cat.label}</Link>
      </MenuItem>
      ))}
      <MenuItem key={'mlb'}>
        <a href="https://www.sportsfeed24.to/streams/mlb" className='menu-items'>MLB</a>
      </MenuItem>
      <MenuItem key={'nhl'}>
      <a href="https://www.sportsfeed24.to/streams/nhl" className='menu-items'>NHL</a>
      </MenuItem>
      <MenuItem key={'cfb'}>
        <a href="https://www.sportsfeed24.to/v2" className='menu-items'>CFB</a>
      </MenuItem>
      <MenuItem key={'ncaa'}>
      <a href="https://www.sportsfeed24.to/v2" className='menu-items'>NCAA</a>
      </MenuItem>
    </>
  );

  return (
    <Header
    className='header'
    >
      <div
        className='header-body'
      >
        <Link to={HOME_PATH}>
          <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            height: '100%', 
            gap: '10px', 
            }}>
          {!matches && <div style={{color: '#fff', marginRight: '10px'}} onClick={() => setHeaderDrawerOpen(true)}>
            <MenuOutlined style={{...styles.headerHamburger(themeConfiguration), color: '#fff'}} />
          </div>}
            <img src={logo} width={200} alt='Streameast Logo' />
          </div>
        </Link>
        <div aria-hidden="true" className='traingle'></div>
        {matches &&
          <Menu mode='horizontal' defaultSelectedKeys={['0']} style={{ width: '100%', background: '#e10600' }}>
            {MenuItems}
          </Menu>}
      </div>
      {/* <div style={{display: 'flex', justifyContent: 'right'}}>
        <Link to='/contact'>
          {matches ? 
          <Button style={{background: '#ffffff', borderRadius: '5px'}}>
          Contact
          </Button>:
          <MailOutlined style={{ color: '#f0f0f0' }}></MailOutlined>}
        </Link>
      </div> */}
      <DrawerComponent
        title='Streameast Soccer'
        open={headerDrawerOpen}
        onClose={() => setHeaderDrawerOpen(false)}
      >
        {
          <Menu
            onClick={() => setHeaderDrawerOpen(false)}
            mode='vertical'
            defaultSelectedKeys={['2']}
            style={styles.headerDrawer}
          >
            {MenuItems}
          </Menu>
        }
      </DrawerComponent>
    </Header>
  );
}
