import { createStyles } from './utils';

export const styles = createStyles({
    header: {
        paddingInline: '0px',
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        width: '100vw',
        backgroundColor: 'white',
    },
    flexDisplay: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    logo:{
        display:'flex',
        justifyContent: 'center', 
        padding:'20px'
    },
    card: {
        paddingBottom:'20px',
        height: '95%',
    },
    paragraph: {
        paddingTop:'10px'
    },
    gridStyle: {
        width: '25%',
        textAlign: 'center',
    },
    homeButton: {
        marginLeft: '5px',
        marginRight: '5px',
        background:'#95100d',
        color: 'white',
        height: '40px',
        borderRadius: '5px',
    },
    imgWidth: {
        width: '50px',
    },
    playerBorder: {
        margin: '0 auto', /* Center the container horizontally */
        boxShadow:'0 0 10px rgba(0, 0, 0, 0.3)', /* Add a subtle shadow for depth */
        width: '1020px',
        height: '600px',
        background: 'black',
        borderTop: '10px solid #95100d',
        borderRight: '10px solid #95100d',
        borderBottom: '10px solid #95100d',
        borderRadius: '10px'
    },
    playerBorderMobile: {
        margin: '0 auto', /* Center the container horizontally */
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', /* Add a subtle shadow for depth */
        width: 'auto',
        height: 'auto',
        background: 'black',
        borderTop: '5px solid #95100d',
        borderRight: '5px solid #95100d',
        borderBottom: '5px solid #95100d',
        borderRadius: '10px'
    },
    shareStream: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    shareText: {
        color: '#fff'
    },
    loadingPlaceholder: {
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '1.2rem',
        color: '#333'
    },
    playerSelect: {
        width: '300px',
    },
    more: {
        fontSize: '18px',
        marginRight: '12px',
        color: '#560c12',
        fontWeight: '600'
    }
});