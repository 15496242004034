export function shareLink(platform: string, data?:any) {
    const fixture = data?.game?.teamB ? `${data?.subCategoryName} : ${data?.game?.teamA} vs ${data?.game?.teamB}`: `${data?.subCategoryName} : ${data?.game?.teamA}`;
    const shareText = `Watch live ${fixture}`
    if(platform === 'facebook'){
        return `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
    }else if (platform === 'twitter'){
        return `https://twitter.com/intent/tweet?url=${window.location.href}&text=${shareText}`;
    
    }else if (platform === 'linkedin'){
        return `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`;
    
    }else if (platform === 'whatsapp'){
        return `https://api.whatsapp.com/send?text=${shareText}+${window.location.href}`;
    
    }else if (platform === 'reddit'){
        return `https://www.reddit.com/submit?url=${window.location.href}&title=${shareText}`;
    
    }else return `https://web.skype.com/share?url=${window.location.href}`;
  }