/* eslint-disable no-console */
import Axios from 'axios';
import { getLocalStorage } from './localStorage';
import { AUTH_TOKEN, LOCAL_STORAGE } from '../utils/constant';
const SERVER_API: string | undefined = process.env.REACT_APP_SERVER_API;
const API_KEY: string | undefined = process.env.REACT_APP_APY_KEY;

export const doGet = async (path: string, headers: object = {}, baseURL = 'https://api-streamast-6e4bj.ondigitalocean.app') => {
  try {
    const token = getLocalStorage(LOCAL_STORAGE.ID_TOKEN);

    const response = await Axios({
      url: `${path}`,
      baseURL: SERVER_API ? SERVER_API : baseURL,
      method: 'GET',
      withCredentials: true,
      headers: setHeader(token, headers, baseURL)
    });

    const { statusText, data: newData, status } = response;

    return {
      statusText,
      data: newData,
      statusCode: status,
    };
  } catch (error: any) {
    return {
      data: {
        statusText: error?.response?.statusText,
        statusCode: error.response.status,
      },
    };
  }
};

export const doPost = async (path: string, data: object, headers: any = {}, baseURL = 'https://api-streamast-6e4bj.ondigitalocean.app') => {
  try {
    const token = getLocalStorage(LOCAL_STORAGE.ID_TOKEN);
    headers['x-api-key'] =  API_KEY;

    const response = await Axios({
      url: path,
      baseURL: SERVER_API ? SERVER_API : baseURL,
      method: 'POST',
      withCredentials: true,
      headers: setHeader(token, headers, baseURL),
      data,
    });

    const { statusText, data: newData, status } = response;

    return {
      statusText,
      data: newData,
      statusCode: status,
    };
  } catch (error: any) {
    return {
      data: {
        statusText: error?.response?.statusText,
        statusCode: error.response.status,
      },
    };
  }
};

// export const doPut = async (path: string, data: object, headers: object = {}, baseURL = 'https://www.sportsfeed24.com') => {
//   try {
//     const token = getLocalStorage(LOCAL_STORAGE.ID_TOKEN);

//     const response = await Axios({
//       url: path,
//       baseURL: SERVER_API ? SERVER_API : baseURL,
//       method: 'PUT',
//       // withCredentials: true,
//       headers: setHeader(token, headers),
//       data,
//     });

//     const { statusText, data: newData, status } = response;

//     return {
//       statusText,
//       data: newData,
//       statusCode: status,
//     };
//   } catch (error: any) {
//     return {
//       data: {
//         statusText: error?.response?.statusText,
//         statusCode: error.response.status,
//       },
//     };
//   }
// };

// export const doPatch = async (path: string, data: object, headers: object = {}, baseURL = 'https://www.sportsfeed24.com') => {
//   try {
//     const token = getLocalStorage(LOCAL_STORAGE.ID_TOKEN);

//     const response = await Axios({
//       url: path,
//       baseURL: SERVER_API ? SERVER_API : baseURL,
//       method: 'PATCH',
//       // withCredentials: true,
//       headers: setHeader(token, headers),
//       data,
//     });

//     const { statusText, data: newData, status } = response;

//     return {
//       statusText,
//       data: newData,
//       statusCode: status,
//     };
//   } catch (error: any) {
//     return {
//       data: {
//         statusText: error?.response?.statusText,
//         statusCode: error.response.status,
//       },
//     };
//   }
// };

// export const doDelete = async (path: string, data: object, headers: object = {}, baseURL = 'https://www.sportsfeed24.com') => {
//   try {
//     const token = getLocalStorage(LOCAL_STORAGE.ID_TOKEN);

//     const response = await Axios({
//       url: path,
//       baseURL: SERVER_API ? SERVER_API : baseURL,
//       method: 'DELETE',
//       // withCredentials: true,
//       headers: setHeader(token, headers),
//       data,
//     });

//     const { statusText, data: newData, status } = response;
//     return {
//       statusText,
//       data: newData,
//       statusCode: status,
//     };
//   } catch (error: any) {
//     return {
//       data: {
//         statusText: error?.response?.statusText,
//         statusCode: error.response.status,
//       },
//     };
//   }
// };

const setHeader = (token: string, headers: object, origin: string) => {
  // List of allowed domains
  const allowedDomains = [process.env.REACT_APP_ORIGIN_1, process.env.REACT_APP_ORIGIN_2];

  const allowOrigin = allowedDomains.includes(origin) ? origin : '';
  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': allowOrigin,
    [AUTH_TOKEN]: token ? `Bearer ${token}` : '',
    ...headers,
  };
};
