/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Divider, Row, Col, Card } from 'antd';
import { Link } from 'react-router-dom';
import { HOME_PATH } from '../../utils/constant';

const { Title, Text } = Typography;

export default function PrivacyPolicy(){
  return (
    <Row style={{display: 'flex', justifyContent:'center', margin: '20px'}}>
        <Col span={18}md={18} xs={24}>
            <Card title='Streameast Soccer' color='red'>
                <Title level={2}>Privacy Policy</Title>
                <Text strong>Last updated: Feb, 1 2024</Text>

            <Divider />

            <Title level={3}>Introduction</Title>
            <Text>
                This Privacy Policy describes our policies and procedures on the collection, use, and disclosure of Your information when you use our service. It tells you about Your privacy rights and how You are protected by the law.
            </Text>

            <Divider />

            <Title level={3}>Interpretation and Definitions</Title>

            <Title level={4}>Interpretation</Title>
            <Text>
                The words of which the initial letter is capitalized have meanings defined under the following conditions. The ensuing delineations shall have the same meaning irrespective of whether they are singular or plural.
                </Text>

                <Title level={4}>Definitions</Title>
                <Row gutter={24}>
                    <ul>
                        <li>Account means a unique account created for You to access our Service or parts of our Service.</li>
                        <li>Affiliate Chapter means a reality that controls, is controlled by, or is under common control with a party, where "control" means the power of 50 or further of the shares, equity interest, or other securities entitled to bounce for the election of directors or other managing authority.</li>
                        <li>Company (referred to as either "the Company", meaning "We", "Us" or "Our" in this Agreement) refers to Streameast Soccer.</li>
                        <li>Cookies are small files that are placed by a website on Your computer, mobile device, or any other device which consist of the details regarding Your browsing history on that website among its many uses.</li>
                        <li>Country: refers to: Globally</li>
                        <li>Device means any tool or gadget that can access the Service such as a computer, a cellphone, or a digital tablet.</li>
                        <li>Personal Data is any information that is unique and specific to an identified or identifiable individual.</li>
                        <li>Service refers to the Website itself.</li>
                        <li>Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to grease the Service, to give the Service on behalf of the Company, to perform services related to the Company, or to help the Company in assaying how the Service is used.</li>
                        <li>Third-party Social Media Service refers to any kind of website or any social network website through which a stoner can log in or create an account to use the Service.</li>
                        <li>Operation Data is the data that is collected automatically, either generated by the use of the Service or from the structure of the service itself (for illustration, the duration of a runner visit).</li>
                        <li>Website refers to Streameast Soccer, you can access the website  
                            <Link to={HOME_PATH} target="_blank"> Streameast Soccer</Link>.
                        </li>
                        <li>You mean the individual accessing or using the Service, or the company, or other legal reality on behalf of which similar existent is penetrating or using the Service, as applicable.</li>
                    </ul>
                </Row>

                <Divider />

                <Row>
                    <Title level={3}>Collection and Use of Your Personal Data</Title>
                    <Title level={4}>Types of Data Collected</Title>
                    <Title level={5}>Personal Data</Title>
                    
                    <Text>While using Our Service, we may ask You to give Us certain identifiable 
                        information that can be used to communicate or identify You. tête-à-tête identifiable 
                        information may include, but isn't limited.
                    </Text>
                </Row>

                <Row>
                    <Title level={5}>Usage Data</Title>
                    
                    <Text>Usage Data is collected automatically when using the Service.</Text>
                    <Text>
                        Operation Data may include information similar to Your Device's Internet Protocol address 
                        (e.g. IP address), cybersurfed type, cybersurfed interpretation, the runners of our Service 
                        that You visit, the time and date of Your visit, the time spent on those runners, 
                        unique device identifiers and other individual data.
                    </Text>

                    <Text>
                    We may also collect information that Your cybersurfed sends whenever You visit 
                    our Service or when You access the Service by or through a mobile device.
                    </Text>

                    <Text>
                    You may also have the option of participating in fresh information with the Company 
                    through Your Third-Party Social Media Services account. However, during enrollment or else, 
                    you’re giving authorization to the company to use, 
                    If You choose to give similar information and Personal Data.
                    </Text>

                </Row>
                <Row>
                    <Title level={4}>Use of Tracking Technologies and Cookies</Title>
                    <Text>
                    Use of Tracking Technologies and Cookies
                    We use Cookies and similar tracking technologies to track the activity on Our Service and 
                    store certain information. Tracking technologies used include beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. 
                    The technologies We use may include:
                    </Text>
                    <ul>
                        <li>Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a cookie is being sent. However, if You do not accept Cookies, then You may not be able to access and use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
                        <li>Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
                        </li>
                    </ul>
                </Row>

                <Divider />

                <Row>
                    <Title level={3}>Use of Your Personal Data</Title>
                    <Title level={4}>The Company may use Personal Data for the following purposes:</Title>
                    <ul>
                        <li>To provide and maintain our Service, including monitoring the usage of our Service.</li>
                        <li>To Manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
                        <li>For the performance of a contract: the development, compliance, and undertaking of the purchase contract for the products, items, or services You have purchased or of any other contract with Us through the Service.</li>
                        <li>To Contact You: To contact You by email, phone calls, text message, or other similar forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
                        <li>To provide you with the latest news, special offers, and general information about other goods, services, and events which we offer that are similar to the ones that you have already purchased or inquired about unless You have opted not to receive such information.</li>
                        <li>To manage Your requests: To attend and manage Your requests to Us.</li>
                        <li>For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</li>
                        <li>For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and to evaluate and improve our Service, products, marketing, and your experience.</li>
                    </ul>
                </Row>

                <Row>
                    <Title level={3}>Children's Privacy</Title>
                    <Text>
                        Our Service doesn't address anyone under the age of 13. We don't deliberately collect tête-à-tête identifiable information from anyone under the age of 13. Still, please communicate with Us, If You're a parent or guardian and You're apprehensive that Your child has handed Us particular data. However, we have ways to remove that information from Our waiters, If We come apprehensive that We've collected Personal Data from anyone under the age of 13 without verification of maternal concurrence.
                    </Text>
                    <Text>
                        If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, we may require Your parent's consent before We collect and use that information.
                    </Text>
                </Row>
                <Row>
                    <Title level={4}>Changes to this Privacy Policy</Title>
                    
                <Text>
                    We may amend and update Our sequestration Policy from time to time. We'll notify You of any changes by posting the new sequestration Policy on this runner.
                </Text>
                <Text>
                    We'll let You know via dispatch and/ or a prominent notice on Our Service before the change gets effective and modernize the " Last streamlined " date at the top of this sequestration Policy.
                </Text>
                <Text>
                    You're advised to review this sequestration Policy from time to time for any changes. Changes to this sequestration Policy are effective as soon as they're posted on this runner.
                </Text>

                </Row>
            </Card>
        </Col>
    </Row>
  );
};